<template>
  <div>
    <b-modal
      id="create_selection_modal"
      centered
      modal-class="custom-modal-variant"
      header-close-variant="cader-gray"
      hide-footer
      @hidden="trackHideFirstModal"
    >
      <template #modal-title>
        <div class="elements-center color-dark-blue">
          <span class="share-rounded-icon">
            <bookmark-icon size="20" />
          </span>
          <span> Create New Selection </span>
        </div>
      </template>
      <div class="mb-2 order-download-modal">
        <div>
          <div>
            <b-form-group>
              <template #label>
                Selection Name <span class="text-danger"> * </span>
              </template>
              <b-form-input
                v-model="selectionName"
                class="rename-input"
                placeholder="Selection Name"
                @input="onInputSelectionName"
              />
            </b-form-group>
            <b-form-group>
              <template #label>
                Collection <span class="text-danger"> * </span>
              </template>
              <b-dropdown
                variant="outline-primary"
                toggle-class="d-flex justify-content-between collection-dropdown"
                block
                :text="selectedCollection.name || 'Select a collection'"
                menu-class="w-100 menu-max-height scroll-bar"
              >
                <template v-if="collections.length">
                  <b-dropdown-item
                    v-for="collection in collections"
                    :key="collection._id"
                    :active="selectedCollection.id === collection._id"
                    @click="onClickCollection(collection)"
                  >
                    {{ collection.name }}
                  </b-dropdown-item>
                </template>
                <template v-else>
                  <b-dropdown-item> No Collections </b-dropdown-item>
                </template>
              </b-dropdown>
            </b-form-group>
          </div>
          <div class="text-center mt-2">
            <b-button
              variant="outline-secondary-info mr-1"
              @click="onCloseSelectionModal"
            >
              Close
            </b-button>
            <b-button
              variant="info"
              :disabled="!isValid"
              @click="onClickNext"
            >
              Next
            </b-button>
          </div>
        </div>
      </div>
    </b-modal>
    <b-modal
      id="wishlist_import_modal"
      ref="wishlist_import_modal"
      centered
      modal-class="custom-modal-variant"
      header-close-variant="cader-gray"
      size="lg"
      hide-footer
      @hide="onHideImportModal"
    >
      <template #modal-title>
        <div class="elements-center color-dark-blue">
          <span class="share-rounded-icon">
            <bookmark-icon size="20" />
          </span>
          <span> Import/Export selection products</span>
        </div>
      </template>
      <div>
        <div>
          Export {{ selectedCollection.name }} collection products
          <b-button
            class="downloadsheet ml-1"
            variant="primary"
            :disabled="isExporting"
            @click="downloadCatalogue"
          >
            <div class="d-flex align-items-center">
              <feather-icon
                id="_id"
                icon="DownloadIcon"
                class="mr-1"
                size="20"
              />
              {{ isExporting ? 'Exporting' : 'Export' }}
            </div>
          </b-button>
        </div>
        <div class="mb-75 mt-25 d-flex align-items-center">
          <div class="my-50 mr-1">
            Choose import type:
          </div>
          <b-form-radio-group
            id="file-radio-group"
            v-model="selectedImportOption"
            name="file-radio-group"
            class="file-radio-btn"
          >
            <b-form-radio
              value="standard"
              class="mb-0"
            >
              Import - Standard
            </b-form-radio>
            <b-form-radio
              value="barcode"
              class="mb-0"
            >
              Import - Barcode
            </b-form-radio>
            <b-form-radio
              value="styleCode"
              class="mb-0"
            >
              Import - Style Code
            </b-form-radio>
          </b-form-radio-group>
        </div>
        <label
          v-if="!productsFile"
          for="uploadCatalog"
          class="fileDownload elements-center"
        >
          <feather-icon
            icon="UploadCloudIcon"
            size="50"
            color="skyblue"
          />
          <h4 class="font-weight-bolder"><b>Drag & Drop</b></h4>
          <h6>or click to select files from your system</h6>
        </label>
        <div
          v-if="productsFile"
          class="d-flex file-droped mt-50"
        >
          <b-img
            :src="xlsxIcon"
            class="mx-1"
            alt="ErrorIcon"
          />
          <span class="catalog-label name">Name: {{ productsFile.name }} </span>
          <span
            class="catalog-label size"
          >Size: {{ (formatNumber(productsFile.size / 1024), 2) }} KB</span>
          <div
            v-if="productsFile"
            class="delete-file d-flex align-items-center justify-content-center"
            @click="productsFile = null"
          >
            <feather-icon
              icon="TrashIcon"
              size="24"
              fill="white"
              color="white"
            />
          </div>
        </div>
        <b-form-file
          id="uploadCatalog"
          v-model="productsFile"
          name="uploadCatalog"
          accept=".xlsx"
          :hidden="true"
          plain
        />
      </div>
      <div class="text-center my-1">
        <b-button
          type="reset"
          class="mt-2 mr-2 px-sm-3 font-weight-bolder"
          variant="outline-info"
          @click="onClickBack"
        >
          Back
        </b-button>
        <b-button
          type="submit"
          variant="info"
          class="mt-2 px-sm-3 font-weight-bolder"
          :disabled="isImporting || !productsFile"
          @click="importSelection"
        >
          {{ isImporting ? 'Importing': 'Import' }}
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import analytics from '@/@core/utils/analytics'
import { apiToastSuccess, apiToastWarning } from '@/@core/utils/toast'
import { formatNumber } from '@/@core/utils/utils'
import constants, { DOWNLOAD_TYPES, FILE_FORMATS } from '@/constants'
import store from '@/store'
import { DOWNLOAD_COLLECTION, GET_COLLECTION_LIST } from '@/store/modules/collection.module'
import { UPLOAD_DOCUMENTS } from '@/store/modules/common.module'
import {
IMPORT_WISHLIST,
SET_SELECTION_NAME,
isEnabledHintPopup,
} from '@/store/modules/shop.module'
import { constants as c } from '@kingpin-global/kingpin-utils-frontend'
import {
BButton,
BDropdown,
BDropdownItem,
BFormFile,
BFormGroup,
BFormInput,
BFormRadio,
BFormRadioGroup,
BImg,
BModal,
} from 'bootstrap-vue'
import { BookmarkIcon } from 'vue-feather-icons'
import { SHOW_DOWNLOADS_NAV_INDICATOR } from '@/store/modules/notification.module'
import { debounce } from 'lodash'

const { FILE_TYPES } = c

export default {
  name: 'CreateSelectionModal',
  components: {
    BModal,
    BButton,
    BDropdown,
    BDropdownItem,
    BFormGroup,
    BFormInput,
    BookmarkIcon,
    FeatherIcon,
    BImg,
    BFormFile,
    BFormRadioGroup,
    BFormRadio,
  },
  props: {
    fetchWishlists: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      selectedCollection: this.buildDefault(),
      selectionName: '',
      collections: [],
      formatNumber,
      productsFile: null,
      xlsxIcon: require('@/assets/images/icons/xlsxIcon.svg'),
      isImporting: false,
      isExporting: false,
      selectedImportOption: 'standard',
      isImportUsingUniqueId: false,
      isImportUsingStyleCode: false,
    }
  },
  computed: {
    userData() {
      return this.$store.getters.profile
    },
    isValid() {
      return !!this.selectedCollection?.id && this.selectionName
    },
  },
   watch: {
    selectedImportOption(newOption) {
      analytics.track(constants.TRACKS.ACTIONS.SELECTIONS.BRAND_CHANGES_ADD_SELECTION_IMPORT_TYPE, {
        newImportType: newOption,
      })
      if (newOption === 'styleCode') {
        this.isImportUsingStyleCode = true
      } else if (newOption === 'barcode') {
        this.isImportUsingUniqueId = true
      } else {
        this.isImportUsingStyleCode = false
        this.isImportUsingUniqueId = false
      }
    },
    productsFile(file) {
      if (file) {
        analytics.track(constants.TRACKS.ACTIONS.SELECTIONS.BRAND_SELECTS_ADD_SELECTION_IMPORT_FILE, {
          fileName: file.name,
        })
      }
    },
  },
  created() {
    this.$store.commit(SET_SELECTION_NAME, '')
    this.getCollections()
  },
  methods: {
    buildDefault() {
      return {
        id: '',
        name: '',
      }
    },
    onClickClose() {
      this.selectedCollection = this.buildDefault()
      this.selectionName = ''
      this.$bvModal.hide('create_selection_modal')
    },
    debouncedTrackSelectionName: debounce(selectionName => {
      analytics.track(constants.TRACKS.ACTIONS.SELECTIONS.BRAND_ENTERS_ADD_SELECTION_NAME, {
        selectionName,
      })
    }, 500),
    onInputSelectionName(text = '') {
      this.selectionName = text
      this.$store.commit(SET_SELECTION_NAME, text)
      this.debouncedTrackSelectionName(text)
    },
    onClickCollection(collection) {
      this.selectedCollection.id = collection._id
      this.selectedCollection.name = collection.name
      analytics.track(
        constants.TRACKS.ACTIONS.BRAND_CHOOSES_COLLECTION_FOR_SELECTION,
        { 'Created By': new Date(), 'Collection Name': collection.name },
      )
    },
    onClickAddProducts() {
      if (!localStorage.getItem(isEnabledHintPopup)) {
        localStorage.setItem(isEnabledHintPopup, true)
      }
      this.$router.push({ path: `products/${this.selectedCollection.id}` })
    },
    getCollections() {
      const params = {
        brandId: this.userData?._id,
      }
      this.$store
        .dispatch(GET_COLLECTION_LIST, params)
        .then(res => {
          this.collections = res.data.data.collections
        })
        .catch(err => {
          apiToastWarning(err)
        })
    },
    onClickNext() {
      this.$bvModal.show('wishlist_import_modal')
      this.$bvModal.hide('create_selection_modal')
      analytics.track(constants.TRACKS.ACTIONS.SELECTIONS.BRAND_CLICKS_ADD_SELECTION_NEXT_BUTTON, {
        selectionName: this.selectionName,
        selectedCollection: this.selectedCollection,
      })
    },
    onClickBack() {
      this.productsFile = null
      this.$bvModal.hide('wishlist_import_modal')
      this.$bvModal.show('create_selection_modal')
      analytics.track(constants.TRACKS.ACTIONS.SELECTIONS.BRAND_CLICKS_ADD_SELECTION_BACK_BUTTON)
    },
    downloadCatalogue() {
      analytics.track(constants.TRACKS.ACTIONS.SELECTIONS.BRAND_CLICKS_ADD_SELECTION_EXPORT_BUTTON, {
        selectedCollection: this.selectedCollection,
      })
      this.isExporting = true
      const payload = {
        respectiveId: this.selectedCollection.id,
        fileType: FILE_FORMATS.XLSX,
        downloadType: DOWNLOAD_TYPES.COLLECTION_EXPORT.VALUE,
      }
      store
        .dispatch(DOWNLOAD_COLLECTION, { payload })
        .then(res => {
          apiToastSuccess(res?.data?.message || 'Download created successfully')
          this.$store.commit(SHOW_DOWNLOADS_NAV_INDICATOR)
        })
        .catch(() => {
          analytics.track(constants.TRACKS.ACTIONS.SELECTIONS.BRAND_ADD_SELECTION_EXPORT_COLLECTION_FAILED, {
            selectedCollection: this.selectedCollection,
          })
        })
        .finally(() => {
          this.isExporting = false
        })
    },
    async importSelection() {
      analytics.track(constants.TRACKS.ACTIONS.SELECTIONS.BRAND_CLICKS_ADD_SELECTION_IMPORT_BUTTON, {
        selectionName: this.selectionName,
        selectedCollection: this.selectedCollection,
        fileName: this.productsFile?.name,
      })
      this.isImporting = true
      try {
        const payload = {
          collectionId: this.selectedCollection.id,
          productsFile: await this.$store.dispatch(UPLOAD_DOCUMENTS, {
            file: this.productsFile,
            fileType: FILE_TYPES.WISHLIST_IMPORT,
          }),
        }
        if (this.selectionName) {
          payload.name = this.selectionName
        }
        const params = {}
        if (this.isImportUsingUniqueId) {
          params.isImportUsingUniqueId = true
        }
        if (this.isImportUsingStyleCode) {
          params.isImportUsingStyleCode = true
        }
        const res = await this.$store.dispatch(IMPORT_WISHLIST, { payload, params })
        apiToastSuccess(res.data.message)
        analytics.track(constants.TRACKS.ACTIONS.SELECTIONS.BRAND_ADD_SELECTION_IMPORT_SUCCESS, {
          selectionName: this.selectionName,
          selectedCollection: this.selectedCollection,
          fileName: this.productsFile?.name,
        })
        this.fetchWishlists()
        this.$bvModal.hide('wishlist_import_modal')
        this.selectionName = ''
        this.selectedCollection = this.buildDefault()
      } catch (err) {
        apiToastWarning(err)
        analytics.track(constants.TRACKS.ACTIONS.SELECTIONS.BRAND_ADD_SELECTION_IMPORT_FAILED, {
          selectionName: this.selectionName,
          selectedCollection: this.selectedCollection,
          fileName: this.productsFile?.name,
        })
      } finally {
        this.isImporting = false
      }
    },
    onHideImportModal() {
      if (!this.isImporting) {
          analytics.track(constants.TRACKS.ACTIONS.SELECTIONS.BRAND_CLOSES_ADD_SELECTION_MODAL, {
          step: 2,
          selectionName: this.selectionName,
          selectedCollection: this.selectedCollection,
          fileName: this.productsFile?.name,
        })
      }
      this.productsFile = null
      this.isImportUsingUniqueId = false
    },
    onCloseSelectionModal() {
      this.selectedCollection = this.buildDefault()
      this.selectionName = null
      this.$bvModal.hide('create_selection_modal')
    },
    trackHideFirstModal() {
      if (!this.$refs.wishlist_import_modal.isVisible) {
        analytics.track(constants.TRACKS.ACTIONS.SELECTIONS.BRAND_CLOSES_ADD_SELECTION_MODAL, {
          step: 1,
          selectionName: this.selectionName,
          selectedCollection: this.selectedCollection,
          fileName: this.productsFile?.name,
        })
      }
    },
  },
}
</script>

<style lang="scss">
@import '~@core/scss/base/components/include'; // Components includes

.collection-dropdown {
  color: $neutral-black;
  border: 1px solid $alice-blue !important;
  box-shadow: inset 0px 0px 4px rgba($info, 0.2);
  border-radius: 4px;
  padding: 16px;
  height: 48px;
}
</style>
